var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.center == "UTAH" && _vm.excavationDetails.meet
        ? _c(
            "v-row",
            { staticClass: "my-4 ml-1" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v("Meet")]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.excavationDetails.meet ? "Yes" : "No") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _vm.center == "UTAH" && _vm.excavationDetails.meet
            ? _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("excavationStartDate")))]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(_vm.excavationDetails.work_date)
                              .format("MM/DD/YYYY")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.center != "UTAH"
            ? _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("excavationStartDate")))]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(_vm.excavationDetails.work_date)
                              .format("MM/DD/YYYY")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.center == "UTAH"
            ? _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v("Meet")]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.excavationDetails.meet ? "Yes" : "No") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("siteContactPhone")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.contact_phone) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("siteContactName")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.contact) + " ")]
              ),
            ],
            1
          ),
          _vm.center !== "UNCC"
            ? _c(
                "v-col",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                  attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.center == "UTAH"
                            ? _vm.$t("subDivisionComplex")
                            : _vm.$t("subDivision")
                        ) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _c("v-col", { staticClass: "pa-0" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "word-wrap": "break-word",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.excavationDetails.subdivision)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _vm.center !== "NCOCC"
            ? _c(
                "v-col",
                { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("workDoneFor")))]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _c("v-col", { staticClass: "pa-0" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "word-wrap": "break-word",
                            },
                          },
                          [_vm._v(_vm._s(_vm.excavationDetails.done_for))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.center === "UNCC"
            ? _c(
                "v-col",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                  attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("excavationAreaOnProperty")) + " ")]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _c("v-col", { staticClass: "pa-0" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "word-wrap": "break-word",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.excavationDetails.location))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }