<template>
  <div>
    <v-row v-if="center == 'UTAH' && excavationDetails.meet" class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">Meet</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.meet ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col v-if="center == 'UTAH' && excavationDetails.meet">
        <v-row class="caption grey--text text--darken-1">{{
      $t("excavationStartDate")
    }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ moment(excavationDetails.work_date).format("MM/DD/YYYY") }}
        </v-row>
      </v-col>
      <v-col v-else-if="center != 'UTAH'">
        <v-row class="caption grey--text text--darken-1">{{
      $t("excavationStartDate")
    }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ moment(excavationDetails.work_date).format("MM/DD/YYYY") }}
        </v-row>
      </v-col>
      <v-col v-else-if="center == 'UTAH'">
        <v-row class="caption grey--text text--darken-1">Meet</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.meet ? "Yes" : "No" }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''">
        <v-row class="caption grey--text text--darken-1">{{
      $t("siteContactPhone")
    }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.contact_phone }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
      $t("siteContactName")
    }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.contact }}
        </v-row>
      </v-col>
      <v-col v-if="center !== 'UNCC'" cols="12" lg="6" xl="6" md="6" sm="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''">
        <v-row class="caption grey--text text--darken-1">{{ center == "UTAH" ? $t("subDivisionComplex") : $t("subDivision") }}
        </v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          <v-col class="pa-0">
            <span style="display: block; word-wrap: break-word">
              {{ excavationDetails.subdivision }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col v-if="center !== 'NCOCC'" cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
      $t("workDoneFor")
    }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          <v-col class="pa-0">
            <span style="display: block; word-wrap: break-word">{{
        excavationDetails.done_for
      }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="center === 'UNCC'" cols="12" lg="6" xl="6" md="6" sm="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''">
        <v-row class="caption grey--text text--darken-1">{{ $t("excavationAreaOnProperty") }}
        </v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          <v-col class="pa-0">
            <span style="display: block; word-wrap: break-word">
              {{ excavationDetails.location }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      center: this.$store.state.Ticket.ticketData.center,
    };
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
